<script lang="ts">
  import "../app.css"

  import { settings, ThemeInit } from "svelte-ux"

  import { GTM_ID } from "$lib/vars"
  import { onMount } from "svelte"

  onMount(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    })
    const f = document.getElementsByTagName("script")[0]
    const j = document.createElement("script")
    j.async = true
    j.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`
    f.parentNode?.insertBefore(j, f)
  })

  let { children } = $props()

  settings()
</script>

<ThemeInit />

<noscript>
  <iframe
    title="noscript"
    src="https://www.googletagmanager.com/ns.html?id={GTM_ID}"
    height="0"
    width="0"
    style="display:none;visibility:hidden"
  ></iframe>
</noscript>

<!--{#if $navigating}-->
<!--  &lt;!&ndash; -->
<!--    Loading animation for next page since svelte doesn't show any indicator. -->
<!--     - delay 100ms because most page loads are instant, and we don't want to flash -->
<!--     - long 12s duration because we don't actually know how long it will take-->
<!--     - exponential easing so fast loads (>100ms and <1s) still see enough progress,-->
<!--       while slow networks see it moving for a full 12 seconds-->
<!--  &ndash;&gt;-->
<!--  <div-->
<!--    class="fixed w-full top-0 right-0 left-0 h-1 z-50 bg-primary"-->
<!--    in:slide={{ delay: 100, duration: 12000, axis: "x", easing: expoOut }}-->
<!--  ></div>-->
<!--{/if}-->

{@render children()}
